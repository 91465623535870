<div *ngIf="!next" class="choose-documents">
  <ng-container *ngIf="documents; else loading">
    <div class="choose-documents__body">
      <div class="choose-documents__header">
        <h3>Choose Documents you need</h3>
        <div class="search">
          <input [(ngModel)]="filter" placeholder="Search" type="text"/>
        </div>
        <p
          *ngIf="typeOfApplication !== AdmissionTypeEnum.DischargeSummary &&
                 typeOfApplication !== AdmissionTypeEnum.POC">
          Please select package:
        </p>
        <div *ngIf="typeOfApplication === AdmissionTypeEnum.SupervisorReport" class="choose-documents__list-item">
          <div class="form-group">
            <input
              (change)="selectAll(AdmissionTypeEnum.SupervisorReport)"
              [checked]="allSelectedSR"
              [disabled]="uncheckSRPackage"
              id="doc-select-all_SR"
              type="checkbox"/>
            <label for="doc-select-all_SR">
              <span class="document-name">Supervisory Visit Package</span>
            </label>
          </div>
        </div>
        <div
          *ngIf="typeOfApplication === AdmissionTypeEnum.Admission ||
               typeOfApplication === AdmissionTypeEnum.SupervisorReport && withAdmission"
          class="choose-documents__list-item">
          <div class="form-group">
            <input
              (change)="selectAll(AdmissionTypeEnum.Admission)"
              [checked]="allSelectedAdmission"
              [disabled]="uncheckAdmissionPackage"
              id="doc-select-all_Admission"
              type="checkbox"/>
            <label for="doc-select-all_Admission">
              <span class="document-name">Start of Care Package</span>
            </label>
          </div>
        </div>
      </div>
      <ng-container *ngIf="typeOfApplication === AdmissionTypeEnum.SupervisorReport">
        <p>Please select a separate Supervisory Visit document:</p>
        <ul
          [class.full]="typeOfApplication === AdmissionTypeEnum.SupervisorReport && withAdmission"
          class="choose-documents__list pb0">
          <ng-container *ngIf="documents.length; else noItems">
            <ng-container *ngFor="let doc of documents">
              <ng-container *ngIf="doc.type === 'supervisory_report'">
                <li *ngIf="showDoc(doc.title)" class="choose-documents__list-item">
                  <div class="form-group">
                    <input
                      (change)="toggle(doc)"
                      [checked]="doc.checked"
                      [disabled]="isDisabledChange(doc)"
                      [id]="'doc' + doc.type + doc.key"
                      type="checkbox"/>
                    <label [for]="'doc' + doc.type + doc.key">
                      <span class="document-name">{{ doc.title }}</span>
                    </label>
                  </div>
                </li>
              </ng-container>
            </ng-container>
          </ng-container>
        </ul>
      </ng-container>
      <ng-container *ngIf="!(typeOfApplication === AdmissionTypeEnum.SupervisorReport && !withAdmission)">
        <p
          *ngIf="typeOfApplication === AdmissionTypeEnum.Admission ||
               typeOfApplication === AdmissionTypeEnum.SupervisorReport &&
               withAdmission">
          Please select a separate Start of Care document:
        </p>
        <p
          *ngIf="typeOfApplication === AdmissionTypeEnum.DischargeSummary ||
                 typeOfApplication === AdmissionTypeEnum.POC">
          Please select document:
        </p>
        <ul class="choose-documents__list">
          <ng-container *ngIf="documents.length; else noItems">
            <ng-container *ngFor="let doc of documents">
              <ng-container *ngIf="doc.type === 'admission' || doc.type === 'discharge' || doc.type === 'poc' ">
                <li *ngIf="showDoc(doc.title)" class="choose-documents__list-item">
                  <div class="form-group">
                    <input
                      (change)="toggle(doc)"
                      [checked]="doc.checked"
                      [disabled]="isDisabledChange(doc)"
                      [id]="'doc' + doc.type + doc.key"
                      type="checkbox"/>
                    <label [for]="'doc' + doc.type + doc.key">
                      <span class="document-name">{{ doc.title }}</span>
                    </label>
                  </div>
                </li>
              </ng-container>
            </ng-container>
          </ng-container>
        </ul>
      </ng-container>
    </div>
    <div *ngIf="documents?.length" class="next-btn-wrap">
      <button
        (click)="toggleStep()"
        [disabled]="!selectedDocs.length"
        class="btn violet next-btn"
        data-e2e="choose-documents-proceed">
        Proceed
      </button>
    </div>
    <ng-template #noItems>
      <div class="message">No documents found</div>
    </ng-template>
  </ng-container>
</div>

<div *ngIf="next" class="choose-documents-action">
  <div class="back-btn-wrapper">
    <span (click)="toggleStep()"><i class="icon-back-arrow"></i> Back to documents</span>
  </div>
  <ul class="choose-documents-action__list">
    <li *ngFor="let doc of selectedDocs">{{ doc.title }}</li>
  </ul>
  <div class="choose-documents-action__buttons">
    <h3>Send for sign by:</h3>
    <ul class="choose-documents-action__buttons-list">
      <li>
        <ng-container *appPermission="[PermissionAdmissionEnum.SendToSign]">
          <button
            (click)="chooseAction('email')"
            *ngIf="typeOfApplication !== AdmissionTypeEnum.DischargeSummary"
            [class.active]="activeEmailField"
            [disabled]="disableAllButtons"
            class="text">
            <i class="icon-mail"></i> Email
          </button>
        </ng-container>
      </li>
      <li>
        <ng-container *appPermission="[PermissionAdmissionEnum.SendToSign]">
          <button
            (click)="chooseAction('phone')"
            *ngIf="typeOfApplication !== AdmissionTypeEnum.DischargeSummary"
            [class.active]="activePhoneField"
            [disabled]="disableAllButtons"
            class="text">
            <i class="icon-file-text1"></i> Text
          </button>
        </ng-container>
      </li>
      <li *appPermission="[PermissionAdmissionEnum.Create, PermissionAdmissionEnum.Update]">
        <button
          (click)="activePhoneField = false; chooseAction('sign')"
          [disabled]="!created || disableAllButtons"
          data-e2e="choose-documents-admission-sign">
          <i class="icon-signature"></i> Sign
        </button>
      </li>
    </ul>
  </div>
  <div *ngIf="errors.length" class="error-list">
    <div *ngFor="let err of errors" class="error">{{ err }}</div>
  </div>
  <div *ngIf="activePhoneField" [class.double]="doubleFields" class="phone-form">
    <div class="phone-form__wrapper">
      <ng-container *ngIf="doubleFields">
        <div class="input-wrapper__container">
          <ng-container
            [ngTemplateOutletContext]="{ showLabel: true }"
            [ngTemplateOutlet]="customerPhone">
          </ng-container>
          <ng-container [ngTemplateOutlet]="customerRepPhone"></ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="!doubleFields">
        <ng-container [ngTemplateOutlet]="customerPhone"></ng-container>
      </ng-container>
      <div class="button-wrapper">
        <button
          (click)="sendDocs('phone')"
          [disabled]="isDisableSendAction || (role === RoleEnum.Employee ? getPhoneSendLength <= 2 : (getPhoneSendLength <= 2 && getPhoneSendRepLength <= 2))"
          class="btn violet send">
          Send
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="activeEmailField" [class.double]="doubleFields" class="phone-form">
    <div class="phone-form__wrapper">
      <ng-container *ngIf="doubleFields">
        <div class="input-wrapper__container">
          <ng-container
            [ngTemplateOutletContext]="{ showLabel: true }"
            [ngTemplateOutlet]="customerEmail">
          </ng-container>
          <ng-container [ngTemplateOutlet]="customerRepEmail"></ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="!doubleFields">
        <ng-container [ngTemplateOutlet]="customerEmail"></ng-container>
      </ng-container>
      <div class="button-wrapper">
        <button
          (click)="sendDocs('email')"
          [disabled]="!created || (!emailSend.length || isDisableSendAction)"
          class="btn violet send">
          Send
        </button>
      </div>
    </div>
  </div>
</div>

<div class="success" style="display: none">
  <div class="success__wrapper">
    <div class="success__header">
      <span class="icon"></span>
    </div>
    <div class="success__body">
      <span>Link successfully sent</span>
    </div>
  </div>
</div>

<ng-template #customerPhone let-showLabel="showLabel">
  <label class="input-wrapper">
    <div *ngIf="showLabel" class="input-wrapper__name">Customer</div>
    <div *ngIf="typeOfApplication === AdmissionTypeEnum.POC" class="input-wrapper__name">Supervisor</div>
    <input
      (keyup)="isDisableSendAction = false"
      [(ngModel)]="phoneSend"
      [class.error]="errorSend.smsMobileNumber || errorSend.phone"
      [mask]="phoneMask"
      appMask
      placeholder="+1 (---) --- - ----"
      type="tel"/>
    <span *ngIf="errorSend.smsMobileNumber || errorSend.phone" class="err-msg">
      {{ errorSend.smsMobileNumber || errorSend.phone }}
    </span>
    <span *ngIf="errorSend.documentKeys" class="err-msg"> {{ errorSend.documentKeys }} </span>
  </label>
</ng-template>

<ng-template #customerRepPhone>
  <label class="input-wrapper">
    <div class="input-wrapper__name">Company Representative</div>
    <input
      [(ngModel)]="phoneSendRep"
      [class.error]="errorSend.smsMobileNumber || errorSend.secondPhone"
      [mask]="phoneMask"
      appMask
      placeholder="+1 (---) --- - ----"
      type="tel"/>
    <span *ngIf="errorSend.smsMobileNumber || errorSend.secondPhone" class="err-msg">
      {{ errorSend.smsMobileNumber || errorSend.secondPhone }}
    </span>
    <span *ngIf="errorSend.documentKeys" class="err-msg"> {{ errorSend.documentKeys }} </span>
  </label>
</ng-template>

<ng-template #customerEmail let-showLabel="showLabel">
  <label class="input-wrapper">
    <div *ngIf="showLabel" class="input-wrapper__name">Customer</div>
    <div *ngIf="typeOfApplication === AdmissionTypeEnum.POC" class="input-wrapper__name">Supervisor</div>
    <input (ngModelChange)="removeError('email')" [(ngModel)]="emailSend" type="text"/>
    <span *ngIf="errorSend?.email" class="err-msg">{{ errorSend.email }}</span>
    <span *ngIf="errorSend?.documentKeys" class="err-msg"> {{ errorSend.documentKeys }} </span>
  </label>
</ng-template>

<ng-template #customerRepEmail>
  <label class="input-wrapper">
    <div class="input-wrapper__name">Company Representative</div>
    <input (ngModelChange)="removeError('secondEmail')" [(ngModel)]="emailSendRep" type="text"/>
    <span *ngIf="errorSend?.secondEmail" class="err-msg">{{ errorSend.secondEmail }}</span>
    <span *ngIf="errorSend?.documentKeys" class="err-msg"> {{ errorSend.documentKeys }} </span>
  </label>
</ng-template>

<ng-template #loading>
  <div class="overlay">
    <img alt="loading" src="/assets/img/datatable/loading.gif"/>
  </div>
</ng-template>
