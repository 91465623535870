// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {version} from './version';
import { webSocket } from './webSocket';

export const environment = {
    production: true,
    apiUrl: 'https://demo-api.oaportal.org/api',
    debug: true,
    logoImage: '/assets/img/openarms.png',
    title: 'Open Arms Home Care',
    client: 'openarms',
    ...version,
    ...webSocket
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
